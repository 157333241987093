<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { api } from '@/api/instances'
import UiButton from '@/components/Layout/UI/UiButton.vue'
import UiBox from '@/components/Layout/UI/UiBox.vue'

const store = useStore()
const emit = defineEmits(['change', 'openModal'])
const props = defineProps({
  team_id: {
    type: Number,
    default: 0
  },
  employee_id: {
    type: Number,
    default: 0
  },
  has_planner_available: {
    type: Boolean,
    default: false
  }
})

const active_business = computed(() => store.state.active_business)
const hasPlanner = computed(() => store.state.permissions.includes('can_see_planner'))
const canSeeBannerPlanner = computed(() => store.state.permissions.includes('can_see_banner_planner'))
const addToastMessage = (data) => store.commit('addToastMessage', data)

const sprintSelected = ref(new Set())
const sprints = ref([])
const open = ref(false)
const openMenu = () => {
  open.value = true
  getSprints()
}
const close = () => { open.value = false }
const vClickOutside = {
  mounted (el) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        close()
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
}
const selectSprint = (sprint_id) => {
  sprintSelected.value.has(sprint_id) ? sprintSelected.value.delete(sprint_id) : sprintSelected.value.add(sprint_id)
}
const getSprints = async () => {
  api.get(`/jarvis/business/${active_business.value}/planner/${props.team_id}/`)
    .then(({ data }) => {
      sprints.value = data.sprints.filter((el) => el.free_seats > 0)
    })
    .catch((error) => {
      console.error(error, 'Ha habido un problema al cargar los sprints')
    })
}
const setUserInSprints = () => {
  api.patch(`/jarvis/business/${active_business.value}/planner/${props.team_id}/${props.employee_id}/assign-seats/`, {
    sprints: [...sprintSelected.value]
  })
    .then(({ data }) => {
      sprintSelected.value.clear()
      addToastMessage({
        title: '¡Éxito!',
        message: 'Se ha completado la asignación correctamente',
        status: 'success'
      })
      emit('change')
      close()
    })
    .catch((error) => {
      addToastMessage({
        title: '¡Error!',
        message: 'Ha habido un problema en la asignación',
        status: 'danger'
      })
      console.error(error, 'Ha habido un problema al asignar los sprints')
    })
  emit('change')
  close()
}
const openModal = () => {
  emit('openModal')
}

const groupSprintsByMonth = computed(() => {
  const grouped = {}
  sprints.value.forEach(sprint => {
    const date = new Date(sprint.start)
    const monthYear = new Intl.DateTimeFormat('es', { month: 'long' }).format(date)
    if (!grouped[monthYear]) {
      grouped[monthYear] = []
    }
    grouped[monthYear].push(sprint)
  })
  return grouped
})

</script>
<template>
  <div class="relative" v-click-outside="close">
    <UiButton v-if="hasPlanner" @click="openMenu" :type="'outline-secondary'" class="ml-2 2xl:text-base xl:text-sm" >
      Planificar
    </UiButton>
    <UiButton v-if="canSeeBannerPlanner" @click="openModal" :type="'outline-secondary'" class="ml-2 2xl:text-base xl:text-sm" >
      Planificar
    </UiButton>
    <UiBox  :classCustom="'shadow-xl'" :width="'w-64'" rounded v-if="open" shadow class="absolute z-40 menu-position">
      <div v-if="sprints.length > 0">
        <h1 class="text-base text-grey mb-4">Planificar licencia:</h1>
        <div class="custom-scroll-sm overflow-y-scroll h-48">
          <div v-for="(sprints, month) in groupSprintsByMonth" :key="month" class="mb-4 border-custom">
            <div class="text-xs font-medium text-[#404040] mb-2 capitalize">{{ month }}</div>
            <div v-for="sprint in sprints" :key="sprint.id" class="flex flex-col pb-2 mb-2">
              <label class="flex items-center w-full">
                <input type="checkbox" @change="selectSprint(sprint.id)">
                <span class="font-medium text-xs text-[#3C67A4] ml-2">
                  {{$filters.shorterDate(sprint.start)}} - {{ $filters.shorterDate(sprint.end) }}
                </span>
              </label>
            </div>
          </div>
        </div>
        <div class="flex justify-end">
          <UiButton :size="'btn-sm'" @click="setUserInSprints" :type="'secondary'">
            Planificar
          </UiButton>
        </div>
      </div>
      <div class="text-2xs" v-else>
        <div v-if="!has_planner_available">
            <p>Equipo sin configurar. Accede al planificador para su configuración y poder planificar las solicitudes.</p>
        </div>
        <div v-else>
          <p class="mb-1">No dispones de asientos libres en este equipo.</p>
          <p>Asigna más licencias o revisa la planificación e inténtalo de nuevo.</p>
        </div>
      </div>
    </UiBox>
  </div>
</template>
<style scoped>
.menu-position {
  bottom: 0;
  right: 0;
  transform: translate(20%, calc( 100% + 12px ) )
}
.border-custom {
  border-bottom: 1px solid #F1F2F5;
}
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 999%;
  width: 14px;
  height: 14px;
  border: 2px solid #ccc;
  outline: none;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  @apply border-primary relative;
}

input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -6%);
  width: 7px;
  height: 7px;
  border-radius: 999%;
  background-color: #3F536F;
}

</style>
