<template>
<div>
  <div class="check" :class="{'active': state}"></div>
</div>
</template>

<script>
export default {
  name: 'UiCheck',
  props: {
    state: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.check.active::before {
  content: '';
  transform: rotate(-45deg);
  position: absolute;
  top: 6px;
  left: 3px;
  display: block;
  width: 10px;
  height: 2px;
  background-color: white;
}
.check.active::after {
  content: '';
  transform: rotate(-45deg);
  position: absolute;
  top: 6px;
  left: 3px;
  display: block;
  width: 2px;
  height: 5px;
  background-color: white;
}
.check.active {
  background: #2D3960;
}
.check {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid #626262;
}
  svg {
    position: absolute;
    top: -1px;
    left: -1px;
    width: 16px;
    height: 16px;
  }
</style>
