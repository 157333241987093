<script setup>
import { onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import filters from '@/filters/index.js'
import ArrowIcon from '../icons/ArrowIcon.vue'
import router from '../../../router'
const store = useStore()
const props = defineProps({
  sprints: {
    type: Array,
    required: true
  },
  monthSaved: {
    type: Number,
    default: 0
  }
})
const calendar = ref([])
const week = ref(['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'])
const months = ref(['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'])
const day = ref('')
const sprints = ref(new Set())
const sprintsFiltered = ref([])
const sprintsWithColor = ref([])
const month = ref('')
const year = ref('')
const initMonth = ref('')
const initYear = ref('')
const defaultDates = () => {
  const date = new Date()
  day.value = date.getDate()
  if (props.monthSaved) {
    month.value = props.monthSaved
  } else {
    month.value = date.getMonth()
  }
  year.value = date.getFullYear()
  initMonth.value = month.value
  initYear.value = year.value
  getDate()
}

const changeMonth = (value) => {
  if (value === 'next') {
    if (month.value === 11) {
      month.value = 0
      store.state.filters.planner.month = 0
      year.value++
    } else {
      month.value++
      store.state.filters.planner.month = month.value
    }
  } else {
    if (month.value === 0) {
      month.value = 11
      store.state.filters.planner.month = 11
      year.value--
    } else {
      month.value--
      store.state.filters.planner.month = month.value
    }
  }
  getDate()
}
const getDate = () => {
  const calendars = []
  const numberOfDays = new Date(year.value, month.value + 1, 0).getDate()
  const firstWeekDay = (new Date(year.value, month.value).getDay() + 6) % 7
  const totalWeeks = Math.ceil((numberOfDays + firstWeekDay) / 7)
  const lastWeek = totalWeeks - 1
  let firstDay = firstWeekDay * -1
  Array.from({ length: totalWeeks }, (_, i) => {
    const week = []
    Array.from({ length: 7 }, (_, d) => {
      firstDay++
      const date = new Date(year.value, month.value, firstDay)
      const data = {
        date: date,
        day: (date.getDate() < 10) ? `0${date.getDate()}` : date.getDate(),
        startDateSprint: false,
        stayInSprint: false,
        endDateSprint: false,
        css: '',
        msg: '',
        sprintName: '',
        sprintIndex: '',
        sprintColor: '',
        sprintId: '',
        hasPointer: false,
        month: month.value,
        year: year.value,
        secondDaySprint: false,
        isInPrimaryMonth: date.getMonth() === month.value,
        isInLastWeekOfPrimaryMonth: week === lastWeek,
        weekday: date.getDay(),
        index: {
          day: firstDay,
          week: i
        }
      }
      week.push(data)
    })
    calendars.push(week)
  })
  calendar.value = calendars
  checkDaysSprintInCalendar()
}
const indexBg = ref(0)
const checkDaysSprintInCalendar = () => {
  sprintsFiltered.value = []
  sprints.value = new Set()
  calendar.value.map(week => {
    week.forEach(day => {
      sprintsWithColor.value.forEach((sprint, index) => {
        const dateStart = new Date(sprint.start)
        const dateEnd = new Date(sprint.end)
        const dateParam = day.date
        if (filters.shortDate(dateStart) === filters.shortDate(dateParam)) {
          sprints.value.add(sprint.id)
        } else if (filters.shortDate(dateEnd) === filters.shortDate(dateParam)) {
          sprints.value.add(sprint.id)
        }
      })
      const filter = sprintsWithColor.value.filter(sprint => sprints.value.has(sprint.id))
      sprintsFiltered.value = [...filter]
    })
    addSprintInCalendar()
  })
}
const addSprintInCalendar = () => {
  calendar.value.map(week => {
    week.forEach(day => {
      sprintsFiltered.value.forEach((sprint, index) => {
        const dateStart = new Date(sprint.start)
        const dateEnd = new Date(sprint.end)
        const dateParam = new Date(day.date)
        const secondDaySprint = new Date(dateStart)
        secondDaySprint.setDate(secondDaySprint.getDate() + 1)
        if (filters.shortDate(dateStart) === filters.shortDate(dateParam)) {
          day.startDateSprint = true
          day.css = `w-[180px]  text-base font-semibold pl-8 ml-2 text-${sprint.color} has-pointer flex items-center rounded-l-lg bg-${sprint.color}`
          day.msg = sprint.name
          day.sprintName = sprint.name
          day.sprintIndex = index
          day.hasPointer = true
          day.sprintId = sprint.id
          day.sprintColor = sprint.color
        } else if (filters.shortDate(dateEnd) === filters.shortDate(dateParam)) {
          day.endDateSprint = true
          day.css = `width-bar mr-8 flex items-center rounded-r-lg bg-${sprint.color}`
          day.sprintIndex = index
          day.sprintName = sprint.name
          day.sprintId = sprint.id
          day.sprintColor = sprint.color
        } else if (filters.shortDate(secondDaySprint) === filters.shortDate(dateParam)) {
          day.stayInSprint = true
          day.css = `w-[180px] pl-6  flex items-center font-bold text-${sprint.color} text-xs bg-${sprint.color}`
          day.sprintColor = sprint.color
          if (sprint.seats.length > 0) {
            day.msg = sprint.free_seats === 0 ? 'Completo' : (sprint.free_seats === 1 ? '1 licencia disponible' : `${sprint.free_seats} licencias disponibles`)
          } else {
            day.msg = 'No dispone de licencias'
          }
          day.sprintIndex = index
          day.sprintName = sprint.name
          day.sprintId = sprint.id
        } else if (dateStart <= dateParam && dateEnd >= dateParam) {
          day.stayInSprint = true
          day.css = `w-[180px]  bg-${sprint.color}`
          day.sprintIndex = index
          day.sprintName = sprint.name
          day.sprintId = sprint.id
          day.sprintColor = sprint.color
        }
      })
    })
  })
}
const addClassActive = (index) => {
  const pointer = document.querySelectorAll(`.bg-${index}`)
  pointer.forEach((element) => {
    element.classList.toggle('active')
    element.classList.toggle('has-tooltip')
  })
}
const goToSprint = (id) => {
  store.state.filters.planner.sprint_id = id
  router.push({ name: 'detail-sprint' })
}
const asignColorToSprint = () => {
  sprintsWithColor.value = []
  indexBg.value = 0
  if (props.sprints) {
    props.sprints.map((sprint) => {
      if (indexBg.value === 4 || indexBg.value === 0) {
        indexBg.value = 0
        sprintsWithColor.value.push({ ...sprint, color: indexBg.value })
        indexBg.value++
      } else {
        sprintsWithColor.value.push({ ...sprint, color: indexBg.value })
        indexBg.value++
      }
    })
  }
}
watch(() => props.sprints, () => {
  asignColorToSprint()
  defaultDates()
  checkDaysSprintInCalendar()
})
onMounted(() => {
  if (props.sprints.length > 0) {
    asignColorToSprint()
    checkDaysSprintInCalendar()
  }
  defaultDates()
})
</script>
<template>
  <div class="border-l w-full border-primary-200">
    <div class="flex pl-6 pt-6 items-center border-b pb-3 border-primary-200 rounded-r-lg">
      <button @click="changeMonth('before')" class="mr-10">
        <ArrowIcon class="rotate-180"/>
      </button>
      <button @click="changeMonth('next')">
        <ArrowIcon/>
      </button>
      <h2 class="ml-5 font-medium text-2xl text-primary">{{ months[month] }} {{year}}</h2>
    </div>
    <div class="calendar">
         <ul>
           <li class="dias text-center" v-for="day in week" :key="day">{{ day }}</li>
         </ul>

         <ul class="week" v-for="week, index in calendar" :key="index">
            <li class="dia flex items-end" :class="(day.isInPrimaryMonth) ? 'mes' : 'month-next'"  v-for="day in week" :key="day">
              <span class="day text-2xs" v-html="day.day"></span>
              <button v-if="day.sprintId" @click="goToSprint(day.sprintId)" @mouseenter="addClassActive(day.sprintColor)" @mouseleave="addClassActive(day.sprintColor)" class="h-16  cursor-pointer" :class="day.css">
                <span v-if="day.hasPointer" :class="`pointer-${day.sprintColor}`" class="flex w-3 h-3 mr-1 rounded-full"></span>
                <span class="z-20">
                  {{ day.msg }}
                </span>
              <span class="tooltip w-full rounded text-base font-medium text-center bg-black shadow-lg p-1 left-0 bg-gray-100 text-white -top-8">{{ day.sprintName }}</span>
            </button>
            </li>
         </ul>
        </div>
      </div>
</template>
<style scoped>
.calendar {
  padding: 0;
  overflow: hidden;
}

.calendar .dias {
  padding: 5px;

}

.calendar ul {
  padding: 0;
}

.calendar ul::after {
  content: '';
  display: table;
  clear: both;
}

.calendar li {
  position: relative;
  display: block;
  float: left;
  width: 14.28%;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.calendar li .day {
  position: absolute;
  top: 5px;
  left: 5px;
}

.calendar li.dia {
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  height: 130px;
  padding-top: 22px;
}

.calendar li.dia.mes {
  background-color: rgba(255, 255, 255, 0.5);
}
.calendar .month-next {
  background-color: #F2F2F2;
}
li.dia:last-child  button {
  padding-right: 22px;
  width: 90%;
  border-top-right-radius: 0.5rem/* 8px */;
  border-bottom-right-radius: 0.5rem/* 8px */;
}
li.dia:first-child  button {
  margin-left: 22px;
  border-top-left-radius: 0.5rem/* 8px */;
  border-bottom-left-radius: 0.5rem/* 8px */;
}
li.dia:first-child  .has-pointer button {
  margin-left: 0px;
}
.bg-0 {
  background-color: #FDD8E0;
}
.bg-1 {
  background-color: #EDE6FF;
}
.bg-2 {
  background-color: #D1EEEF;
}
.bg-3 {
  background-color: #F9FFD6;
}
.bg-0.active {
  background-color: #F9819C;
}
.bg-1.active {
  background-color: #A384FF;
}
.bg-2.active {
  background-color: #41B9BE;
}
.bg-3.active {
  background-color: #E1FF33;
}

.text-0 {
  color: #3A030F;
}
.text-1 {
  color: #3F297E;
}
.text-2 {
  color: #1A4A4C;
}

.pointer-0 {
  background-color: #FF5D82;
}
.pointer-1 {
  background-color: #A384FF;
}
.pointer-2 {
  background-color: #45BABF;
}
.pointer-3 {
  background-color: #829900;
}
.active .pointer-0 {
  background-color: #FF5D82;
}
.active .pointer-1 {
  background-color: #5131b3;
}
.active .pointer-2 {
  background-color: #1A4A4C;
}
.active .pointer-3 {
  background-color: #829900;
}
.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}
.width-bar {
  @apply w-[85px] xl:w-[87px] 2xl:w-[140px];
 @media (min-width: 1440px) {
  width: 103px;
 }
}
</style>
