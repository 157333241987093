<script setup>
import { onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import DateFilterIcon from '@/components/Icons/DateFilterIcon.vue'
import router from '../../../router'
const store = useStore()
const props = defineProps({
  sprints: {
    type: Array
  }
})
const months = ref(['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto',
  'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'])
const indexBg = ref(0)
const sprintsByYearAndMonth = ref({})
const assignColorAndYearToSprint = () => {
  sprintsByYearAndMonth.value = {}
  if (props.sprints) {
    props.sprints.map((sprint) => {
      const year = new Date(sprint.start).getFullYear()
      const month = new Date(sprint.start).getMonth()

      if (!sprintsByYearAndMonth.value[year]) {
        sprintsByYearAndMonth.value[year] = new Array(12).fill(null).map(() => [])
      }
      if (indexBg.value === 4 || indexBg.value === 0) {
        indexBg.value = 0
        sprintsByYearAndMonth.value[year][month].push({ ...sprint, color: indexBg.value })
        indexBg.value++
      } else {
        sprintsByYearAndMonth.value[year][month].push({ ...sprint, color: indexBg.value })
        indexBg.value++
      }
    })
  }
}
const goToSprint = (id) => {
  store.state.filters.planner.sprint_id = id
  router.push({ name: 'detail-sprint' })
}
watch(() => props.sprints, () => {
  assignColorAndYearToSprint()
})
onMounted(() => {
  assignColorAndYearToSprint()
})
</script>
<template>
  <div class="pt-7">
    <h2 class="font-medium text-primary text-xl mb-2">Gestionar Planificación</h2>
    <div class="overflow-hidden custom-scroll overflow-y-auto h-[700px]">
      <div class="mt-2 " v-for="monthss, year in sprintsByYearAndMonth" :key="year">
        <div v-for="(sprints, monthIndex) in monthss" :key="monthIndex">
          <div v-if="sprints.length > 0">
          <div class="flex items-center mb-2">
            <DateFilterIcon class="w-4 h-4 mr-1"/>
            <h4 class="text-primary text-sm font-medium">{{ months[monthIndex] }} {{ year }}</h4>
          </div>
            <ul class="w-full ">
              <li class="w-full text-sm mb-2  flex items-center"  v-for="sprint in sprints" :key="sprint.id">
                <button class="flex w-full hover:bg-grey-lightest rounded py-1 items-center" @click="goToSprint(sprint.id)">
                  <div class="flex w-full justify-between">
                    <div class="flex w-2/5  items-center ">
                      <span :class="`pointer-${sprint.color}`" class="w-2 h-2 mr-2 flex rounded-full"></span>
                      <span :class="`text-${sprint.color} font-medium`">{{sprint.name}}</span>
                    </div>
                    <span class="text-primary w-3/5">{{$filters.shorterDate(sprint.start)}} - {{ $filters.shorterDate(sprint.end) }}</span>
                  </div>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.pointer-0 {
  background-color: #FF5D82;
}
.pointer-1 {
  background-color: #A384FF;
}
.pointer-2 {
  background-color: #45BABF;
}
.pointer-3 {
  background-color: #829900;
}
.text-0 {
  color: #3A030F;
}
.text-1 {
  color: #3F297E;
}
.text-2 {
  color: #1A4A4C;
}
.bg-0 {
  background-color: #FDD8E0;
}
.bg-1 {
  background-color: #EDE6FF;
}
.bg-2 {
  background-color: #D1EEEF;
}
.bg-3 {
  background-color: #F9FFD6;
}
</style>
